<script>
	export let hobbies;
</script>

<p class="hobbies">{hobbies}</p>

<style>
	.hobbies {
		margin: 0;
		text-align: center;
	}
</style>